import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from "react";
import resim from "../assets/logo.png";
import telegramLogo from "../assets/telegram.webp";
import twitterLogo from "../assets/xpng.png";
import pump from "../assets/pump.png";
import dex from "../assets/dex.png";

const Banner = () => {
  const theme = useTheme();
  const mdD = useMediaQuery(theme.breakpoints.down("md"));
  const [walletAddress, setWalletAddress] = useState("");

  const isPhantomInstalled = () => {
    return window?.solana?.isPhantom || false;
  };

  const connectWallet = async () => {
    if (isPhantomInstalled()) {
      try {
        const resp = await window.solana.connect();
        setWalletAddress(resp.publicKey.toString());
        console.log("Wallet Public Key:", resp.publicKey.toString());
      } catch (err) {
        console.error("Wallet connection failed:", err);
      }
    } else {
      alert("Phantom Wallet is not installed. Please install it to proceed.");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: "black",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExN3BpZGgwY2F5bThwamw1Zmc3Mnoxa2JxdzJxeHE1emlmanhuZzFkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/5uXA1eFJoPaXlVusGP/giphy-downsized-large.gif')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid item xs={12} md={5}></Grid>
      <Grid
        container
        item
        xs={11}
        md={6}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          padding: 4,
          borderRadius: 2,
        }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={resim}
          alt="logo"
          style={{ width: mdD ? "100px" : "150px", marginBottom: "20px" }}
        />
        <Typography
          sx={{
            fontSize: mdD ? 16 : 20,
            fontFamily: "'Pacifico', cursive",
            textAlign: "center",
          }}
        >
          FREE THE BOOBIEZ, PROTECT THE CITIES!
        </Typography>
        <Typography
          sx={{
            fontSize: mdD ? 40 : 60,
            fontFamily: "'Pacifico', cursive",
            fontWeight: 400,
            textAlign: "center",
            my: 3,
          }}
        >
          BOOBIEZ TOKEN
        </Typography>
        <Typography
          sx={{
            fontSize: mdD ? 20 : 30,
            fontFamily: "'Pacifico', cursive",
            color: "white",
            textAlign: "center",
            mb: 3,
          }}
        >
          We’re on a mission to freeing the boobiez
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Pacifico', cursive",
            color: "white",
            textAlign: "center",
            mb: 3,
            wordBreak: "break-word", // Metni bölmek için
            overflowWrap: "break-word", // Uzun kelimeleri kaydırmak için
            whiteSpace: "normal", // Tek satırda kalmayı engeller
            fontSize: mdD ? "12px" : "16px", // Mobilde daha küçük font boyutu
          }}
        >
          CA: Gym58vkByYk7moD7YLN3D2A6Ce4sZQLLsx8Hq5y7pump
        </Typography>
        {!walletAddress ? (
          <Button
            variant="outlined"
            sx={{
              borderColor: "white",
              color: "white",
              borderRadius: "8px",
              fontSize: mdD ? "16px" : "20px",
              padding: "10px 20px",
              fontFamily: "'Pacifico', cursive",
              mb: 3,
            }}
            onClick={connectWallet}
          >
            Connect Wallet
          </Button>
        ) : (
          <Typography
            sx={{
              fontSize: mdD ? 14 : 20,
              color: "white",
              mb: 2,
              textAlign: "center",
            }}
          >
            Connected Wallet:{" "}
            {`${walletAddress.slice(0, 3)}...${walletAddress.slice(-3)}`}
          </Typography>
        )}
        <Button
          variant="outlined"
          sx={{
            borderColor: "white",
            color: "white",
            borderRadius: "8px",
            fontSize: mdD ? "16px" : "20px",
            padding: "10px 20px",
            fontFamily: "'Pacifico', cursive",
            mb: 3,
          }}
          onClick={() => {
            window.location.href = "/slot";
          }}
        >
          Free Spin Coming Soon!
        </Button>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <a
              href="https://t.me/BOOBIEZofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={telegramLogo}
                alt="Telegram"
                style={{
                  width: mdD ? "40px" : "50px",
                  cursor: "pointer",
                  filter: "invert(1) grayscale(1) brightness(2)",
                }}
              />
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://x.com/boobiezofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitterLogo}
                alt="Twitter"
                style={{
                  width: mdD ? "40px" : "50px",
                  cursor: "pointer",
                  filter: "invert(1) grayscale(1) brightness(2)",
                }}
              />
            </a>
          </Grid>
          <Grid item>
            <a href="https://x.com/" target="_blank" rel="noopener noreferrer">
              <img
                src={dex}
                alt="Dextools"
                style={{
                  width: mdD ? "40px" : "50px",
                  cursor: "pointer",
                  filter: "invert(1) grayscale(1) brightness(2)",
                }}
              />
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://pump.fun/coin/Gym58vkByYk7moD7YLN3D2A6Ce4sZQLLsx8Hq5y7pump"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={pump}
                alt="Pump"
                style={{
                  width: mdD ? "40px" : "50px",
                  cursor: "pointer",
                  filter: "invert(1) grayscale(1) brightness(2)",
                }}
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Banner;
