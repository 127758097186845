import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RealMain from "./pages/RealMain";
import EmptyPage from "./pages/Empty";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<RealMain />} />
          <Route path="/slot" element={<EmptyPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
