import { Grid } from "@mui/material";
import slotImage from "../assets/slott.png";
import slotImage2 from "../assets/slot2.png";
import { useMediaQuery, useTheme } from "@mui/system";

const EmptyPage = () => {
  const theme = useTheme();
  const mdD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: "black",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${mdD ? slotImage2 : slotImage})`, // Slot PNG'yi arka plan resmi olarak kullandık
        backgroundSize: "cover", // Resmi tam ekran olarak ayarla
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></Grid>
  );
};

export default EmptyPage;
